<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-6">
                                <v-text-field
                                    v-model="name"
                                    :rules="name_rules"
                                    label="Tên"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="thumbnail"
                                    label="Hình ảnh (Sử dụng ảnh vuông)"
                                    required
                                    :append-icon="'mdi-paperclip'"
                                    @click:append="browse_file"
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    type="number"
                                    v-model="ordinal"
                                    label="Ordinal"
                                ></v-text-field>
                            </div>

                            <input type="file" ref="upload" @change="upload_server" style="display:none;">

                            <div class="col-md-12">
                                <h5>Nội dung</h5>
                                <vue-editor v-model="content"></vue-editor>
                            </div>
                            
                            <div class="col-md-12">
                                <v-btn class="ma-2 float-right" :loading="loading" color="info" @click="create_post">Thêm</v-btn>
                                <v-btn class="ma-2 float-right" :loading="loading" @click="cancel">Huỷ</v-btn>
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { VueEditor } from "vue2-editor";

export default {
    metaInfo: {
        title: 'Thêm bài viết'
    },
    data() {
        return {
            valid: true,
            name: "",
            ordinal: "",
            content: "",
            thumbnail: "",
            loading: false,
            name_rules: [],
        };
    },
    watch: {
        
    },
    computed: {
        
    },
    components: {
        VueEditor
    },
    methods: {
        create_post() {
            this.loading = true;
            var payload = {
                Name: this.name,
                Ordinal: parseInt(this.ordinal),
                Content: this.content,
                Thumbnail: this.thumbnail,
                Create_time: Math.floor(new Date().getTime() / 1000),
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('posts/create_post', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'posts-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$router.push({ name: 'posts-index' });
        },
        browse_file() {
            this.$refs.upload.click();
        },
        upload_server() {
            var self = this;
            this.$store.dispatch('uploads/upload', {file: this.$refs.upload.files[0]}).then(res => {
                self.thumbnail = res.data.url;
            }).catch(e => {
                console.log(e);
            });
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Tin tức", route: "index" }, { title: "Thêm bài viết" } ]);
    }
};
</script>



